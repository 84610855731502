import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Seo from "../../components/seo"
import Breadcrumb from "../../components/Breadcrumb/index"
import { CategoryMain } from "../../styles/Layout"
import {
  FeaturedImage,
  HeroContent,
} from "../../components/Category/CategoryElements"
import {
  QuotesSection,
  QuoteContainer,
  QuoteContent,
  QuoteBlock,
} from "../../components/Quote/QuoteElements"
import Newsletter from "../../components/Newsletter"

const QuoteSubcategoryPage = ({ data }) => {
  const quotes = data.contentfulQuote.subCategory.quote
  // const quoteMeta = data.quoteCategory.nodes[0]

  const image = getImage(data.contentfulQuote.subCategory.featuredImage)
  const featuredImgSrc = getSrc(data.contentfulQuote.subCategory.featuredImage)

  return (
    <>
      <Seo
        title={data.contentfulQuote.subCategory.title}
        description={data.contentfulQuote.subCategory.seoDescription}
        image={featuredImgSrc}
        imageAlt={
          data.contentfulQuote.subCategory.featuredImage.description ||
          data.contentfulQuote.subCategory.title
        }
        article={false}
      />

      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={image}
            alt={data.contentfulQuote.subCategory.title}
            style={{
              height: "59vh",
              gridColumn: "1/-1",
              gridRow: "1/-1",
            }}
          />
          <Breadcrumb
            className={null}
            categoryTitle={
              data?.contentfulQuote?.subCategory?.parentCategory?.title
            }
            categoryLink={
              data?.contentfulQuote?.subCategory?.parentCategory?.slug
            }
            subCategoryTitle={data?.contentfulQuote?.subCategory?.title}
            subCategoryLink={`/${data?.contentfulQuote?.subCategory?.title}`}
          />
          <HeroContent>
            <h1 className="bg-grad">
              {data.contentfulQuote.subCategory.title}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulQuote.subCategory.description
                    .childMarkdownRemark.html,
              }}
            />
          </HeroContent>
        </FeaturedImage>

        <QuotesSection>
          <QuoteContainer>
            {quotes.map(quote => {
              return (
                <QuoteContent key={quote.id}>
                  <Link
                    to={`/${quote.category.slug}/${quote.subCategory.slug}/${quote.slug}/`}
                  >
                    <QuoteBlock cite={quote.author.name}>
                      <p>{quote.body.body}</p>
                    </QuoteBlock>
                    <figcaption>
                      {/* <div>
                      <p>{quote.title}</p>
                      <span>By {quote.author.name}</span>
                    </div>

                    <span>#Ashalleyistics</span> */}

                      <div>
                        <p>{quote.title}</p>
                        {/* <span>#Ashalleyistics</span> */}
                        <span>
                          {quote.date} | {quote.author.name}
                        </span>
                        <br />
                        <span>#Ashalleyistics</span>
                      </div>
                    </figcaption>
                  </Link>
                </QuoteContent>
              )
            })}
          </QuoteContainer>
        </QuotesSection>
        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default QuoteSubcategoryPage

export const query = graphql`
  query ($id: String) {
    contentfulQuote(id: { eq: $id }) {
      subCategory {
        id
        title
        seoDescription
        description {
          childMarkdownRemark {
            html
          }
        }
        parentCategory {
          title
          slug
        }
        featuredImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 1.777777
            quality: 80
          )
        }
        quote {
          id
          title
          slug
          date(formatString: "MM.DD.YYYY")
          category {
            slug
          }
          subCategory {
            slug
          }
          body {
            body
          }
          author {
            name
          }
        }
      }
    }
  }
`
